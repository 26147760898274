/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
import { getI18nLanguage } from '@/plugins/i18n'
import { apiHttp } from '../axiosApi'
import {
  userAll,
  userSave,
  userLogin,
  userPath,
  userResetPassword,
  userChangePassword,
  userValidate,
  userValidateDownload,
  userActivity,
  userActivityPaged,
  userNotification,
  userPing,
  userActivityFile,
  userGroup,
  userPhoneEmail,
} from '../config/apiRoute'

export const getUsers = async () => apiHttp('GET', `${userAll}`)
export const getUser = async userId => apiHttp('GET', `${userPath}${userId}`)
export const getUsersActivity = async () => apiHttp('GET', `${userActivity}`)
export const getUserActivityById = async id => apiHttp('GET', `${userActivity}${id}`)
export const getUsersByGroup = async groupId => apiHttp('GET', `${userGroup}${groupId}`)
export const getUsersActivityPaged = async (filter, order, count, page) =>
  apiHttp('GET', `${userActivityPaged}?filter=${filter}&order=${order}&count=${count}&page=${page}`)
export const getValidateUser = async userId => apiHttp('GET', `${userValidate}${userId}`)
export const logUserActivity = async body => apiHttp('POST', `${userActivity}`, body, {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})
export const getUsersActivityFile = async () => {
  const { accessToken } = localStorage

  return apiHttp(
    'GET',
    `${userActivityFile}`,
    { },
    {
      headers: {
        'el-token': `Bearer ${accessToken}`,
        'Accept-Language': 'en',
      },
    },
  )
}
export const createUser = async body => apiHttp('POST', `${userSave}`, body)
export const editUser = async (body, userId) => apiHttp('PUT', `${userPath}${userId}`, body)
export const deleteUser = async id => apiHttp('DELETE', `${userPath}${id}`)
export const getUserPing = async () => apiHttp('GET', `${userPing}`)

export const loginApi = ({ username, password }) =>
  apiHttp(
    'POST',
    `${userLogin}`,
    { username, password },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${btoa(`${username}:${password}`)}`,
      },
    },
  )

export const resetPassword = async userId => apiHttp('GET', `${userResetPassword}${userId}`)
export const changePassword = async (userId, body) =>
  apiHttp('PUT', `${userChangePassword}${userId}`, body)
export const validatePassword = async password => apiHttp('GET', `${userValidateDownload}${password}`)
export const searchUserByPhoneEmail = async (phone, email) => apiHttp('GET', `${userPhoneEmail}${phone}/${email}`)

export const userSendNotification = async body => apiHttp('POST', `${userNotification}`, body)
export const userSendNotificationForm = async formdata => {
  const { accessToken } = localStorage

  return apiHttp(
    'POST',
    `${userNotification}`,
    formdata,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        'el-token': `Bearer ${accessToken}`,
        'Access-Control-Allow-Origin': '*',
        'Accept-Language': getI18nLanguage(),
      },
    },
  )
}
