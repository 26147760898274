import { apiHttp } from '../axiosApi'
/* eslint-disable object-curly-newline */
// eslint-disable-next-line object-curly-newline
import {
  reservationPath,
  reservationWeekRange,
  reservationFacilityWeekRange,
  reservationFacility,
  reservationDetail,
  reservationAddPlayer,
  reservationRemovePlayer,
  reservationMarkAllPaid,
  reservationMarkAsPaid,
  reservationCancel,
  reservationNoReason,
  reservationPos,
  reservationPosAmount,
  reservationPosProducts,
  reservationConfirm,
  reservationAvailabilityTime,
  reservationPaged,
  recurringReservation,
  recurringfacility,
  recurringCancel,
  recurringChangeEndDate,
  recurringReservationPath,
  recurringVerify,
  recalculatePayment,
  reservationSelectedMarkAsPaid,
  reservationChangePlayersQty,
} from '../config/apiRoute'

export const getReservationWeekRange = async (date, week) => apiHttp('GET', `${reservationWeekRange}${date}/${week}`)
export const getReservationFacilityWeekRange = async (facilityId, date, week, loading = true) => apiHttp('GET', `${reservationFacilityWeekRange}${facilityId}/${date}/${week}`, {}, {}, loading)
export const getReservationByFacilityDate = async (facilityId, date, loading = true) => apiHttp('GET', `${reservationFacility}${facilityId}/${date}`, {}, {}, loading)
export const getReservationByFacility = async facilityId => apiHttp('GET', `${reservationFacility}${facilityId}`)
export const getReservationPaged = async (filter, order, count, page) => apiHttp('GET', `${reservationPaged}/?filter=${filter}&order=${order}&count=${count}&page=${page}`)
export const getReservationDetail = async id => apiHttp('GET', `${reservationDetail}${id}`)
export const getReservationNoReasonByFacility = async (facilityId, loading = false) => apiHttp('GET', `${reservationNoReason}${facilityId}`, {}, {}, loading)
export const getReservation = async id => apiHttp('GET', `${reservationPath}${id}`)
export const createReservation = async body => apiHttp('POST', `${reservationPath}`, body)
export const updateReservation = async (id, body) => apiHttp('PUT', `${reservationPath}${id}`, body)
export const confirmReservation = async id => apiHttp('PUT', `${reservationConfirm}${id}`)
export const cancelReservation = async (id, body) => apiHttp('PUT', `${reservationCancel}${id}`, body)
export const addPlayerReservation = async (id, userId) => apiHttp('PUT', `${reservationAddPlayer}${id}/${userId}`)
export const removePlayerReservation = async (id, userId) => apiHttp('PUT', `${reservationRemovePlayer}${id}/${userId}`)
export const markAllPaidReservation = async id => apiHttp('PUT', `${reservationMarkAllPaid}${id}`)
export const markAsPaidReservation = async id => apiHttp('PUT', `${reservationMarkAsPaid}${id}`)
export const getReservationPos = async facilityId => apiHttp('GET', `${reservationPos}${facilityId}`)
export const getReservationPosAmount = async reservationId => apiHttp('GET', `${reservationPosAmount}${reservationId}`)
export const getReservationPosProducts = async reservationId => apiHttp('GET', `${reservationPosProducts}${reservationId}`)
export const createReservationPosProducts = async body => apiHttp('POST', `${reservationPosProducts}`, body)
export const updateReservationPosProducts = async (reservationId, body) => apiHttp('PUT', `${reservationPosProducts}${reservationId}`, body)
export const getReservationAvailabilityTime = async (fieldId, date, startTime) => apiHttp('GET', `${reservationAvailabilityTime}${fieldId}/${date}/${startTime}`)

export const createRecurringReservation = async body => apiHttp('POST', `${recurringReservation}`, body)
export const getRecurringReservationfacility = async facilityId => apiHttp('GET', `${recurringfacility}${facilityId}`)
export const cancelRecurringReservation = async (reservationId, body) => apiHttp('PUT', `${recurringCancel}${reservationId}`, body)
export const updateRecurringEndDate = async (reservationId, body) => apiHttp('PUT', `${recurringChangeEndDate}${reservationId}`, body)
export const getRecurringReservation = async id => apiHttp('GET', `${recurringReservationPath}${id}`)
export const verifyRecurringReservation = async (fieldId, startDate, endDate, startTime, endTime, frequency) => apiHttp('GET', `${recurringVerify}${fieldId}/${startDate}/${endDate}/${startTime}/${endTime}/${frequency}`)
export const recalculateReservationPayment = async body => apiHttp('PUT', `${recalculatePayment}`, body, {}, true, 'v2')
export const selectedMarkAsPaidReservation = async (id, body) => apiHttp('PUT', `${reservationSelectedMarkAsPaid}${id}`, body, {}, true, 'v2')
export const changePlayersQtyReservation = async (id, body) => apiHttp('PUT', `${reservationChangePlayersQty}${id}`, body, {}, true, 'v2')
