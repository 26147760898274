const views = [
  // ——— User ——————————————————
  {
    path: '/views/user/list',
    name: 'views-user-list',
    component: () => import('@/views/users/UserList.vue'),
    meta: {
      layout: 'content',
      id: 6,
    },
  },
  {
    path: '/views/user/form/:id?',
    name: 'views-user-form',
    component: () => import('@/views/users/UserForm.vue'),
    meta: {
      layout: 'content',
      id: 6,
    },
  },

  // ——— User App ——————————————————
  {
    path: '/views/user-app/list',
    name: 'views-user-app-list',
    component: () => import('@/views/users-apps/UserAppList.vue'),
    meta: {
      layout: 'content',
      id: 7,
    },
  },
  {
    path: '/views/user-app/form/:id?',
    name: 'views-user-app-form',
    component: () => import('@/views/users-apps/UserAppForm.vue'),
    meta: {
      layout: 'content',
      id: 7,
    },
  },

  // ——— Sport ——————————————————
  {
    path: '/views/sport/list',
    name: 'views-sport-list',
    component: () => import('@/views/sports/SportList.vue'),
    meta: {
      layout: 'content',
      id: 11,
    },
  },
  {
    path: '/views/sport/form/:id?',
    name: 'views-sport-form',
    component: () => import('@/views/sports/SportForm.vue'),
    meta: {
      layout: 'content',
      id: 11,
    },
  },

  // ——— Ranking Group ——————————————————
  {
    path: '/views/ranking-group/list',
    name: 'views-ranking-group-list',
    component: () => import('@/views/ranking-group/RankingGroupList.vue'),
    meta: {
      layout: 'content',
      id: 40,
    },
  },
  {
    path: '/views/sport/form/:id?',
    name: 'views-ranking-group-form',
    component: () => import('@/views/ranking-group/RankingGroupForm.vue'),
    meta: {
      layout: 'content',
      id: 40,
    },
  },

  // ——— Order History ——————————————————
  {
    path: '/views/order-history/list',
    name: 'views-order-history-list',
    component: () => import('@/views/order-history/order-history-list/OrderHistoryList.vue'),
    meta: {
      layout: 'content',
      id: 2,
    },
  },
  {
    path: '/views/order-history/preview/:id',
    name: 'views-order-history-preview',
    component: () => import('@/views/order-history/order-history-preview/OrderHistoryPreview.vue'),
    meta: {
      layout: 'content',
      id: 2,
    },
  },
  {
    path: '/views/order-history/video/preview/:id',
    name: 'views-order-history-video-preview',
    component: () => import('@/views/videos/video-preview/VideoPreview.vue'),
    meta: {
      layout: 'content',
      id: 2,
    },
  },

  // ——— Payments ——————————————————
  {
    path: '/views/payment/list',
    name: 'views-payment-list',
    component: () => import('@/views/payments/payment-list/PaymentList.vue'),
    meta: {
      layout: 'content',
      id: 3,
    },
  },
  {
    path: '/views/payment/preview/:id',
    name: 'views-payment-preview',
    component: () => import('@/views/payments/payment-preview/PaymentPreview.vue'),
    meta: {
      layout: 'content',
      id: 3,
    },
  },
  {
    path: '/views/payment/form/:id?',
    name: 'views-payment-form',
    component: () => import('@/views/payments/payment-preview/PaymentForm.vue'),
    meta: {
      layout: 'content',
      id: 3,
    },
  },

  // ——— Fanaty Payments ——————————————————
  {
    path: '/views/fanaty-payment/list',
    name: 'views-fanaty-payment-list',
    component: () => import('@/views/fanaty-payments/payment-list/PaymentList.vue'),
    meta: {
      layout: 'content',
      id: 22,
    },
  },
  {
    path: '/views/fanaty-payment/preview/:id',
    name: 'views-fanaty-payment-preview',
    component: () => import('@/views/fanaty-payments/payment-preview/PaymentPreview.vue'),
    meta: {
      layout: 'content',
      id: 22,
    },
  },
  {
    path: '/views/fanaty-payment/form/:id?',
    name: 'views-fanaty-payment-form',
    component: () => import('@/views/fanaty-payments/payment-preview/PaymentForm.vue'),
    meta: {
      layout: 'content',
      id: 3,
    },
  },

  // ——— POS Payments ——————————————————
  {
    path: '/views/pos-payment/list',
    name: 'views-pos-payment-list',
    component: () => import('@/views/pos-payments/payment-list/PaymentList.vue'),
    meta: {
      layout: 'content',
      id: 24,
    },
  },
  {
    path: '/views/pos-payment/preview/:id',
    name: 'views-pos-payment-preview',
    component: () => import('@/views/pos-payments/payment-preview/PaymentPreview.vue'),
    meta: {
      layout: 'content',
      id: 24,
    },
  },
  {
    path: '/views/pos-payment/form/:id?',
    name: 'views-pos-payment-form',
    component: () => import('@/views/pos-payments/payment-preview/PaymentForm.vue'),
    meta: {
      layout: 'content',
      id: 24,
    },
  },

  // ——— Payment Methods ——————————————————
  {
    path: '/views/payment-method/list',
    name: 'views-payment-method-list',
    component: () => import('@/views/payment-methods/PaymentMethodList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 4,
    },
  },

  // ——— Order Videos ——————————————————
  {
    path: '/views/order-video/list',
    name: 'views-order-video-list',
    component: () => import('@/views/order-videos/order-video-list/OrderVideoList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 1,
    },
  },

  // ——— Videos List ——————————————————
  {
    path: '/views/video/list',
    name: 'views-video-list',
    component: () => import('@/views/videos/video-list/VideoList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 5,
    },
  },
  {
    path: '/views/video/preview/:id',
    name: 'views-video-preview',
    component: () => import('@/views/videos/video-preview/VideoPreview.vue'),
    meta: {
      layout: 'content',
      id: 5,
    },
  },

  // ——— Facilities ——————————————————
  {
    path: '/views/facility/list',
    name: 'views-facility-list',
    component: () => import('@/views/facilities/FacilityList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 9,
    },
  },
  {
    path: '/views/facility/form/:id?',
    name: 'views-facility-form',
    component: () => import('@/views/facilities/FacilityForm.vue'),
    meta: {
      layout: 'content',
      id: 9,
    },
  },

  // ——— Fields ——————————————————
  {
    path: '/views/field/list',
    name: 'views-field-list',
    component: () => import('@/views/fields/FieldList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 10,
    },
  },
  {
    path: '/views/field/form/:id?',
    name: 'views-field-form',
    component: () => import('@/views/fields/FieldForm.vue'),
    meta: {
      layout: 'content',
      id: 10,
    },
  },

  // Onsite Payment Methods
  {
    path: '/views/onsite-payment-method/list',
    name: 'onsite-payment-method-list',
    component: () => import('@/views/onsite-payment-methods/OnSitePaymentMethodList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 30,
    },
  },
  {
    path: '/views/onsite-payment-method/form/:id?',
    name: 'views-onsite-payment-method-form',
    component: () => import('@/views/onsite-payment-methods/OnSitePaymentMethodForm.vue'),
    meta: {
      layout: 'content',
      id: 30,
    },
  },

  // ——— Cameras ——————————————————
  {
    path: '/views/camera/list',
    name: 'views-camera-list',
    component: () => import('@/views/cameras/CameraList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 8,
    },
  },
  {
    path: '/views/camera/form/:id?',
    name: 'views-camera-form',
    component: () => import('@/views/cameras/CameraForm.vue'),
    meta: {
      layout: 'content',
      id: 8,
    },
  },

  // ——— User Activity ——————————————————
  {
    path: '/views/user-activity/list',
    name: 'views-user-activity-list',
    component: () => import('@/views/user-activity/UserActivityList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 12,
    },
  },
  {
    path: '/views/user-activity/form/:id?',
    name: 'views-user-activity-form',
    component: () => import('@/views/user-activity/UserActivityForm.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 12,
    },
  },

  // ——— Highlight ——————————————————
  {
    path: '/views/highlight/list',
    name: 'views-highlight-list',
    component: () => import('@/views/highlights/HighlightList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 13,
    },
  },
  {
    path: '/views/highlight/form/:id?',
    name: 'views-highlight-form',
    component: () => import('@/views/highlights/HighlightForm.vue'),
    meta: {
      layout: 'content',
      id: 13,
    },
  },

  // ——— App Notification ——————————————————
  {
    path: '/views/notification/list',
    name: 'views-notification-list',
    component: () => import('@/views/notifications/NotificationList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 14,
    },
  },

  // ——— Groups ——————————————————
  {
    path: '/views/group/list',
    name: 'views-group-list',
    component: () => import('@/views/groups/GroupList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 15,
    },
  },
  {
    path: '/views/group/form/:id?',
    name: 'views-group-form',
    component: () => import('@/views/groups/GroupForm.vue'),
    meta: {
      layout: 'content',
      id: 15,
    },
  },

  // ——— Roles ——————————————————
  {
    path: '/views/role/list',
    name: 'views-role-list',
    component: () => import('@/views/roles/RoleList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 16,
    },
  },
  {
    path: '/views/role/form/:id?',
    name: 'views-role-form',
    component: () => import('@/views/roles/RoleForm.vue'),
    meta: {
      layout: 'content',
      id: 16,
    },
  },

  // ——— Notifications ——————————————————
  {
    path: '/views/console-notification/list',
    name: 'views-console-notification-list',
    component: () => import('@/views/console-notifications/ConsoleNotificationList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 18,
    },
  },
  {
    path: '/views/console-notification/form/:id?',
    name: 'views-console-notification-form',
    component: () => import('@/views/console-notifications/ConsoleNotificationForm.vue'),
    meta: {
      layout: 'content',
      id: 18,
    },
  },

  // ——— Recurring Orders ——————————————————
  {
    path: '/views/recurring-order/list',
    name: 'views-recurring-order-list',
    component: () => import('@/views/recurring-orders/RecurringOrderList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 19,
    },
  },
  {
    path: '/views/recurring-order/form/:id?',
    name: 'views-recurring-order-form',
    component: () => import('@/views/recurring-orders/RecurringOrderForm.vue'),
    meta: {
      layout: 'content',
      id: 19,
    },
  },

  // ——— Welcome ——————————————————
  {
    path: '/views/welcome',
    name: 'views-welcome',
    component: () => import('@/views/welcome/Welcome.vue'),
    meta: {
      layout: 'content',
      allow: true,
    },
  },

  // ——— Dashboard ——————————————————
  {
    path: '/views/dashboard',
    name: 'views-dashboard',
    component: () => import('@/views/dashboards/dashboard/Dashboard.vue'),
    meta: {
      layout: 'content',
      id: 20,
    },
  },

  // ——— Field Monitoring ——————————————————
  {
    path: '/views/field-monitoring/list',
    name: 'views-field-monitoring-list',
    component: () => import('@/views/field-monitoring/FieldMonitoringList.vue'),
    meta: {
      layout: 'content',
      id: 21,
    },
  },

  // Inventory
  {
    path: '/views/inventory/list',
    name: 'views-inventory-list',
    component: () => import('@/views/inventory-adjustments/InventoryList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 29,
    },
  },
  {
    path: '/views/inventory/form/:id?',
    name: 'views-inventory-form',
    component: () => import('@/views/inventory-adjustments/InventoryForm.vue'),
    meta: {
      layout: 'content',
      id: 29,
    },
  },

  // Product Categories
  {
    path: '/views/products-categories/form/:id?',
    name: 'views-products-categories-form',
    component: () => import('@/views/product-categories/ProductCategoryForm.vue'),
    meta: {
      layout: 'content',
      id: 28,
    },
  },
  {
    path: '/views/products-categories/list',
    name: 'views-products-categories-list',
    component: () => import('@/views/product-categories/ProductCategoryList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 28,
    },
  },

  // ——— Players Group ——————————————————
  {
    path: '/views/players-group/list',
    name: 'views-players-group-list',
    component: () => import('@/views/players-group/PlayersGroupList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 25,
    },
  },
  {
    path: '/views/players-group/form/:id?',
    name: 'views-players-group-form',
    component: () => import('@/views/players-group/PlayersGroupForm.vue'),
    meta: {
      layout: 'content',
      id: 25,
    },
  },

  // ——— Reservations Schedule ——————————————————
  {
    path: '/views/reservations/schedule',
    name: 'views-reservations-schedule',
    component: () => import('@/views/reservations/Reservations.vue'),
    meta: {
      layout: 'content',
      id: 23,
    },
  },

  // ——— Recurring Reservations ——————————————————
  {
    path: '/views/recurring-reservation/list',
    name: 'views-recurring-reservation-list',
    component: () => import('@/views/recurring-reservations/ReservationList.vue'),
    meta: {
      layout: 'content',
      id: 35,
    },
  },
  {
    path: '/views/recurring-reservations/form/:id?',
    name: 'views-recurring-reservations-form',
    component: () => import('@/views/recurring-reservations/RecurringReservationForm.vue'),
    meta: {
      layout: 'content',
      id: 35,
    },
  },
  {
    path: '/views/reservations/form/:id?',
    name: 'views-reservations-form',
    component: () => import('@/views/recurring-reservations/ReservationForm.vue'),
    meta: {
      layout: 'content',
      id: 35,
    },
  },

  // ——— Reservation ——————————————————
  {
    path: '/views/reservation/',
    name: 'views-console-reservation',
    component: () => import('@/views/reservation/ReservationsMain.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 31,
    },
  },

  // ——— Metrics ——————————————————
  {
    path: '/views/metrics/',
    name: 'views-console-metrics',
    component: () => import('@/views/metrics/MetricsMain.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 32,
    },
  },

  // ——— Communications ——————————————————
  {
    path: '/views/communications/',
    name: 'views-console-communications',
    component: () => import('@/views/communications/CommunicationsMain.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 33,
    },
  },

  // ——— Products ——————————————————
  {
    path: '/views/product/list',
    name: 'views-product-list',
    component: () => import('@/views/products/ProductList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 27,
    },
  },
  {
    path: '/views/product/form/:id?',
    name: 'views-product-form',
    component: () => import('@/views/products/ProductForm.vue'),
    meta: {
      layout: 'content',
      id: 27,
    },
  },

  // ——— Checkout - POS ——————————————————
  {
    path: '/views/checkout/',
    name: 'views-checkout',
    component: () => import('@/views/checkout/Checkout.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 26,
    },
  },

  // ——— Pay Finished ——————————————————
  {
    path: '/views/pay-finished',
    name: 'views-pay-finished',
    component: () => import('@/views/pay-finished/PayFinished.vue'),
    meta: {
      layout: 'content',
      allow: true,
    },
  },

  // ——— Terminal ——————————————————
  {
    path: '/views/terminal/list',
    name: 'views-terminal-list',
    component: () => import('@/views/terminals/TerminalList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 34,
    },
  },
  {
    path: '/views/terminal/form/:id?',
    name: 'views-terminal-form',
    component: () => import('@/views/terminals/TerminalForm.vue'),
    meta: {
      layout: 'content',
      id: 34,
    },
  },

  // ——— Contacts ——————————————————
  {
    path: '/views/contact/list',
    name: 'views-contact-list',
    component: () => import('@/views/contacts/ContactList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 36,
    },
  },
  {
    path: '/views/contact/form/:isContact/:id?',
    name: 'views-contact-form',
    component: () => import('@/views/contacts/ContactForm.vue'),
    meta: {
      layout: 'content',
      id: 36,
    },
  },

  // ——— Blocked Times ——————————————————
  {
    path: '/views/blocked-times/list',
    name: 'views-blocked-times-list',
    component: () => import('@/views/blocked-times/BlockedTimesList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 38,
    },
  },
  {
    path: '/views/blocked-times/form/:id?',
    name: 'views-blocked-times-form',
    component: () => import('@/views/blocked-times/BlockedTimesForm.vue'),
    meta: {
      layout: 'content',
      id: 38,
    },
  },

  // ——— Blocked Times ——————————————————
  {
    path: '/views/cancelled-reasons/list',
    name: 'views-cancelled-reasons-list',
    component: () => import('@/views/cancellation-reasons/CancellationReasonList.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: 39,
    },
  },
  {
    path: '/views/cancelled-reasons/form/:id?',
    name: 'views-cancelled-reasons-form',
    component: () => import('@/views/cancellation-reasons/CancellationReasonForm.vue'),
    meta: {
      layout: 'content',
      id: 39,
    },
  },

  // ——— Videos View ——————————————————
  {
    path: '/views/video/view',
    name: 'views-video-view',
    component: () => import('@/views/video-view/VideoView.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: [1, 5, 13, 21],
    },
  },

  // ——— Facility Settings View ——————————————————
  {
    path: '/views/facility-settings/view',
    name: 'views-facility-settings-view',
    component: () => import('@/views/facility-settings-view/FacilitySettings.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: [8, 10, 30, 38],
    },
  },

  // ——— Fanaty Settings View ——————————————————
  {
    path: '/views/fanaty-settings/view',
    name: 'views-fanaty-settings-view',
    component: () => import('@/views/fanaty-settings-view/FanatySettingsView.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: [11],
    },
  },

  // ——— Organization Settings View ——————————————————
  {
    path: '/views/organization-settings/view',
    name: 'views-organization-settings-view',
    component: () => import('@/views/organization-settings-view/OrganizationSettingsView.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: [11],
    },
  },

  // ——— Reports View ——————————————————
  {
    path: '/views/reports/view',
    name: 'views-reports-view',
    component: () => import('@/views/reports-view/ReportsView.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: [20],
    },
  },

  // ——— Billing View ——————————————————
  {
    path: '/views/billing/view',
    name: 'views-billing-view',
    component: () => import('@/views/billing-view/BillingView.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: [3, 4],
    },
  },

  // ——— Booking View ——————————————————
  {
    path: '/views/booking/view',
    name: 'views-booking-view',
    component: () => import('@/views/booking-view/BookingView.vue'),
    meta: {
      layout: 'content',
      resource: 'Demo',
      id: [23, 31, 35],
    },
  },
]

export default views
