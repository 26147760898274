/* eslint-disable import/prefer-default-export */
import { apiHttp } from '../axiosApi'
import {
  matchAddGuest,
  matchAddUser,
  matcRemoveUser,
} from '../config/apiRoute'

export const addGuest = async body => apiHttp('POST', `${matchAddGuest}`, body)
export const addUserMatch = async body => apiHttp('POST', `${matchAddUser}`, body, {}, true, 'v2')
export const removeUserMatch = async id => apiHttp('DELETE', `${matcRemoveUser}${id}`, {}, {}, true, 'v2')
